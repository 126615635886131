






























































































import { Component,Ref,Vue } from 'vue-property-decorator'
import DialogoDeEdicaoDeGrupoEconomico from '@/components/grupoeconomico/DialogoDeEdicaoDeGrupoEconomico.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { DeleteGrupoEconomicoUseCase, FindGrupoEconomicoUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { GrupoEconomico } from '@/models/GrupoEconomico'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'


@Component({
	components:{
		DialogoDeEdicaoDeGrupoEconomico,
		Confirmacao,
	},
})

export default class TelaDeGrupoEconomico extends Vue {
	@Ref() dialogo!: DialogoDeEdicaoDeGrupoEconomico

	mostrar = false
	findGrupoEconomicoUseCase = new FindGrupoEconomicoUseCase()
	deleteGrupoEconomicoUseCase = new DeleteGrupoEconomicoUseCase()

	gruposEconomicos: GrupoEconomico[] = []
	carregando = false
	excluindo: string[] = []

	get podeAlterarGrupoEconomico() {
		return UserLoginStore.temAutorizacao(montarOperacaoDeRecurso('alterar', 'grupos-economicos'))
	}

	get podeDeletarGrupoEconomico() {
		return UserLoginStore.temAutorizacao(montarOperacaoDeRecurso('deletar', 'grupos-economicos'))
	}

	get podeCriarGrupoEconomico() {
		return UserLoginStore.temAutorizacao(montarOperacaoDeRecurso('criar', 'grupos-economicos'))
	}

	get temAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}
	
	async created() {
		try {
			this.carregando = true
			this.gruposEconomicos = (await this.findGrupoEconomicoUseCase.find())
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	atualizarGrupoEconomico(grupoEconomico: GrupoEconomico){
		if (!this.gruposEconomicos) return
		const indice = this.gruposEconomicos.findIndex(
			grupoExistente => grupoExistente.id === grupoEconomico.id,
		)
		indice === -1
			? this.gruposEconomicos.push(grupoEconomico)
			: this.gruposEconomicos.splice(indice, 1, grupoEconomico)
	}

	abrirDialogoDeEdicao(){
		this.dialogo.mostrar()
	}

	async excluirGrupoEconomico(indice: number) {
		const grupoEconomico = this.gruposEconomicos[indice]
		if (!grupoEconomico) return

		try {
			this.excluindo.push(grupoEconomico.id)
			await this.deleteGrupoEconomicoUseCase.delete(grupoEconomico.id)
			this.gruposEconomicos = this.gruposEconomicos.filter(
				({ id }) => id !== grupoEconomico.id,
			)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.excluindo = this.excluindo.filter(id => id !== grupoEconomico.id)
		}
	}
}

